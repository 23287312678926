import * as React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { StaticImage } from "gatsby-plugin-image"
import ProductCategory from "../../components/ProductCategory/ProductCategory"
import PageLinks from "../../components/PageLinks/PageLinks"

const superdrill = () => {
  return (
    <>
      <Seo title="슈퍼드릴" />
      <Layout>
        <SimpleBanner subtitle="방전가공" title="슈퍼드릴 DRILLING EDM">
          <StaticImage
            className="banner__image"
            src="../../images/chmer/chmerpic2-min.png"
            layout="constrained"
            placeholder="blurred"
          />
        </SimpleBanner>
        <ProductCategory productCategory="superdrill" />
        <PageLinks />
      </Layout>
    </>
  )
}

export default superdrill
